
/* eslint-disable @typescript-eslint/camelcase */
import { mixins, Options } from "vue-class-component";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import OverlayPanel from "primevue/overlaypanel";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { LocationController } from "@/app/ui/controllers/LocationController";
import InputDimension from "@/app/ui/components/input-dimension/index.vue";
import InputAdornment from "@/app/ui/components/input-adornment/index.vue";
import convertDecimal from "@/app/infrastructures/misc/common-library/ConvertDecimal";
import convertDecimalAfterComma from "@/app/infrastructures/misc/common-library/ConvertDecimalAfterComma";
import convertPhoneNumberV2 from "@/app/infrastructures/misc/common-library/ConvertPhoneNumberV2";
import {
  CustomProcessEditAddressForm,
  CustomProcessSttDetail,
  RequestUpdate,
  ResponseUpdate,
  SttFailedDetail,
  CustomProcessSttData,
  CustomProcessReason,
  RequestPatchReverseDestination
} from "@/domain/entities/CustomProcess";
import DownloadCsv from "@/app/ui/views/out-going-shipment/out-going-mixin";
import { CustomProcessController } from "@/app/ui/controllers/CustomProcessController";
import Print from "../../modules/print.vue";
import { playNotification } from "@/app/infrastructures/misc/UtilsAudio";
import debounce from "lodash/debounce";
import { TrackingController } from "@/app/ui/controllers/TrackingController";
import SelectSearchV2 from "@/app/ui/components/select-search/index.vue";
import { PartnerController } from "@/app/ui/controllers/PartnerController";
import EditAddress from "./edit-address.vue";
import { ShipmentBookingController } from "@/app/ui/controllers/ShipmentBookingController";
import { ClientPartnerOptions } from "@/domain/entities/CnManifest";
import { ShipmentBookingDetail } from "@/domain/entities/ShipmentBooking";
import { DistrictData } from "@/domain/entities/Location";
import { PartnerDetail } from "@/domain/entities/Partner";
import { UpdateStatusApiRequest } from "@/data/payload/api/CustomProcessApiRequest";
import { ERROR_SPESIFICATION } from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import {
  ErrorSpesificationModal,
  OptionsClass
} from "@/domain/entities/MainApp";
import { Commodity } from "@/domain/entities/Commodity";
import { ProductDeliveryData } from "@/domain/entities/Booking";
import { PodDexController } from "@/app/ui/controllers/PodDexController";
import { ReasonData } from "@/domain/entities/PodDex";
import { ReasonListDex } from "@/data/payload/api/PodDexRequest";
import { FlagsMedusa } from "@/feature-flags/flags-misc-medusa";

@Options({
  components: {
    DetailLayout,
    OverlayPanel,
    InputDimension,
    InputAdornment,
    Print,
    SelectSearchV2,
    EditAddress
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (!this.openSuccess && !this.isPartial) {
      this.onOpenClosePage(to);
      if (this.answer) {
        this.answer = false;
        next();
      } else {
        next(false);
      }
    } else {
      next();
      this.setPartial(false);
      this.setOpenSuccess(false);
    }
  }
})
export default class UpdateV2 extends mixins(DownloadCsv) {
  refs: any = "";
  mounted() {
    AccountController.checkAccountLocation();
    this.getReason();
    this.refs = this.$refs;
    this.fetchDetailPartner();
    this.getReasonList();
    CustomProcessController.setIsEditAddress(false)
    CustomProcessController.setConsigneePhone('');
    CustomProcessController.setUnableProccessMisbooking(true);
    CustomProcessController.setReverseDestination(new RequestPatchReverseDestination({}));
  }
  unmounted() {
    this.setPartial(false);
    this.setOpenSuccess(false);
    TrackingController.initTrackingStt();
  }

  // delete stt
  sttNumberListTrash: Array<string> = [];
  showDeleteSttNumber = false;
  sttNumberToDelete = "";
  sttNumberSuccessDelete = "";
  errorSttNumberDelete = "";
  async onValidateDeleteSttNumber(val: any) {
    const splitVal = val.split("#");
    if (splitVal.length > 0) this.sttNumberToDelete = splitVal[0];
    this.onDeleteSttNumber();
  }
  onDeleteSttNumber() {
    if (this.sttNumberToDelete) {
      this.errorSttNumberDelete = "";
      this.sttNumberSuccessDelete = "";
      this.sttNumberToDelete = this.sttNumberToDelete.toUpperCase();

      // find index no. stt
      const indexSttNumber = this.dataSttToBeUpdate.findIndex(
        (data) => data.sttNo === this.sttNumberToDelete
      );

      // no. stt not found
      if (indexSttNumber < 0) {
        this.errorSttNumberDelete = this.$t(
          "No. STT tidak ditemukan/sudah terhapus"
        );
      } else {
        this.dataSttToBeUpdate.splice(indexSttNumber, 1);
        this.sttNumberListTrash.push(this.sttNumberToDelete);
        this.sttNumberSuccessDelete = this.sttNumberToDelete;
        this.sttNumberToDelete = "";
      }
    }
    const deleteStt: any = this.$refs.deleteStt;
    deleteStt?.focus();
  }
  onShowDelete() {
    this.errorSttNumberDelete = "";
    this.showDeleteSttNumber = true;
    this.sttNumberToDelete = "";
    this.sttNumberSuccessDelete = "";
  }
  onCloseDelete() {
    this.showDeleteSttNumber = false;
    this.sttNumberListTrash = [];
  }

  // get profile account
  get dataProfile() {
    return CustomProcessController.dataProfile;
  }
  get parentId() {
    return AccountController.accountData.account_type_detail.id;
  }
  get detailDistrict() {
    return LocationController.districtDetail;
  }
  get typeUser() {
    return CustomProcessController.typeUser;
  }
  isStatusSelect = false;
  onStatusSelect(value: boolean) {
    this.isStatusSelect = value;
  }
  onSelectStatus(name: string, value: string) {
    this.form.status = value;
    this.form.statusName = name;
    this.form.remarks = '';
    this.form.reasonCode = '';
    this.getReasonListAfterSelect();
    if (this.IS_REVERSE_JOURNEY || this.IS_MISBOOKING) {
      this.assignDataEditAddress(new CustomProcessEditAddressForm());
      this.dataSttToBeUpdate = [];
      this.form.cityCode = this.IS_MISBOOKING && this.isInternal ? this.form.partnerName?.key?.cityCode : ""
      if (this.IS_MISBOOKING && this.isConsole) {
        this.disabledBackgroundMisbooking = true;
        this.form.remarks = "Kecamatan/kota pada resi tidak sesuai detail alamat.";
      }
    }
    CustomProcessController.setReverseDestination(new RequestPatchReverseDestination({}));
    CustomProcessController.setIsEditAddress(false)
  }

  disabledBackgroundMisbooking = false;

  getReasonListAfterSelect() {
    if (this.isHalCd || this.isRejected) {
      this.getReasonList();
    }
  }

  getReason() {
    CustomProcessController.fetchCustomProcessReasonList();
  }

  get customProcessReasonList() {
    if (CustomProcessController.customProcessReasonListStatus.length) {
      return CustomProcessController.customProcessReasonListStatus.filter(
        (item: CustomProcessReason) => {
          return FlagsMedusa.custom_status_halcd.isEnabled()
            ? item
            : item.value !== "HALCD";
        }
      );
    }

    return [];
  }

  // handle stt number
  isPaid = true;
  isUnpaidNotification = false;
  errorSttNumber = "";
  isValidateScan = false;

  onCloseNotification() {
    this.isUnpaidNotification = false;
    this.notification = false;
  }

  onResetForm() {
    this.form.sttNumber = "";
    this.isValidateScan = false;
    this.isErrorValidationScan = false;
    this.errorSttNumber = "";
    this.isErrorTyping = false;

    // re focus
    const inputStt: any = this.refs.inputStt;
    inputStt.focus();
  }

  onValidateSttNumber = debounce(async (val: any) => {
    if (!this.isDisableAddSttNumber) {
      this.isScan = true;
      this.isValidateScan = true;
      const splitVal = val.split("#");
      if (splitVal.length > 0) this.form.sttNumber = splitVal[0];
      await this.addSttNumber();
    }
  }, 500);

  // add stt number
  isScan = false;
  async addSttNumber() {
    if (!this.isDisableAddSttNumber) {
      this.onCloseNotification();
      this.isScan = false;
      let getTracking = false;
      if (!this.isSttNumber(this.form.sttNumber)) {
        getTracking = await TrackingController.trackStt({
          sttNo: this.form.sttNumber,
          isGetSla: false
        });
      }
      const sttTrackingData =
        TrackingController.trackingSttData.sttDetail.sttNo;
      let payload: any = {
        sttNo: getTracking ? sttTrackingData : this.form.sttNumber,
        customProcessStatus: this.form.status,
        callback: (detailStt: CustomProcessSttData) => {
          this.addSttNumberToUpdate(detailStt);
          if (detailStt.stt.length) {
            this.getDetailStt(detailStt.stt[0]);
          }
        }
      };
      payload = this.isInternal
        ? { ...payload, partnerId: this.form.partnerId }
        : payload;
      await CustomProcessController.getCustomProcessSttDetail(payload);
    }
  }

  isSttNumber(sttNumber: string) {
    return /^\d{1,2}LP\w+$/g.test(sttNumber.trim());
  }

  addSttNumberToUpdate(detailStts: any) {
    if (detailStts.isAllowUpdateStatus) {
      for (const detailStt of detailStts.stt) {
        this.onValidateSttResult(detailStts, detailStt);
      }

      if (!this.isErrorTyping) {
        // reset form
        this.onResetForm();
        this.notification = this.isValidateScan;
        if (!this.isErrorValidationScan) {
          this.isValidateScan = false;
        }

        // remove notification after 15 second
        if (this.isPaid) {
          setTimeout(() => {
            this.notification = false;
          }, 15000);
        }
      }
    } else {
      this.isErrorValidationScan = this.isValidateScan;
      this.isErrorTyping = !this.isValidateScan;
      this.errorSttNumber = detailStts.errorMessage || detailStts.message.id;
      playNotification("error");
    }
  }
  onValidateSttResult(detailStts: any, detailStt: any) {
    const indexCurrentSttNumber = this.dataSttToBeUpdate.findIndex(
      (data) => data.sttNo === detailStt.sttNo
    );
    if (indexCurrentSttNumber > -1) {
      playNotification("error");
      this.errorSttNumber = "No. STT sudah di input";
      this.isErrorValidationScan = this.isValidateScan;
      this.isErrorTyping = !this.isValidateScan;
      this.isValidateScan = false;
    } else {
      this.isPaid = detailStts.isPaid;
      if (this.isPaid) {
        // store to data table
        this.dataSttToBeUpdate.push({
          ...detailStt,
          sttNoRefExternal: this.isSttNumber(this.form.sttNumber)
            ? "-"
            : this.form.sttNumber
        });
        playNotification("success");
      } else {
        playNotification("error");
        this.isUnpaidNotification = !detailStt.isPaid;
      }
      this.isValidateScan = true;
      this.detailSttNotification = {
        sttElexysNumber: detailStt.sttElexysNo,
        sttNumber: detailStt.sttNo,
        destinationCity: detailStt.sttDestinationCityName,
        originCity: detailStt.sttOriginCityName,
        lastStatus: detailStt.sttLastStatusId,
        totalPieces: detailStt.sttTotalPiece,
        chargeableWeight: detailStt.sttChargeableWeight
      };
    }
  }
  get totalStt() {
    return this.dataSttToBeUpdate.length;
  }
  get calculatedStt() {
    return this.dataSttToBeUpdate.reduce(
      (a: any, b: any) => {
        return {
          totalPieces: a.totalPieces + b.sttTotalPiece,
          totalGrossWeight: a.totalGrossWeight + b.sttGrossWeight,
          totalVolumeWeight: a.totalVolumeWeight + b.sttVolumeWeight,
          totalChargeableWeight:
            a.totalChargeableWeight + b.sttChargeableWeight,
        };
      },
      {
        totalPieces: 0,
        totalGrossWeight: 0,
        totalVolumeWeight: 0,
        totalChargeableWeight: 0
      }
    );
  }
  get totalPieces() {
    return this.calculatedStt.totalPieces;
  }
  get totalGrossWeight() {
    return this.calculatedStt.totalGrossWeight;
  }
  get totalVolumeWeight() {
    return this.calculatedStt.totalVolumeWeight;
  }

  // pagination
  pagination = {
    page: 1,
    limit: 50
  };

  // alert popup
  isErrorTyping = false;
  isErrorValidationScan = false;
  isPartial = false;
  setPartial(value: boolean) {
    this.isPartial = value;
  }
  get isPopup(): boolean {
    return (
      this.openSuccess ||
      this.isErrorTyping ||
      this.isFailed ||
      this.isPartial ||
      this.isErrorValidationScan
    );
  }
  onDownloadCsvFailed() {
    this.downloadCsvFailedStt({
      fileName: "custom_process_stt_failed.csv",
      listStt: this.sttFailedList
    });
  }

  togglePanelPrintSuccess(event: any) {
    const refs: any = this.$refs;
    refs["op-success"]?.toggle(event);
  }
  async print(type: string, language = "id") {
    this.setOpenSuccess(false);
    const book: any = await import(
      "@/app/ui/views/shipment/booking/create-booking/payment-detail/book.vue"
    );
    const printType: any = {
      thermal: () =>
        book.default.methods.printBasic(
          this.sttReturn.sttId,
          0,
          language,
          false,
          true
        ),
      basic: () => book.default.methods.printBasic(this.sttReturn.sttId, 1)
    };
    await printType[type]();
    this.setOpenSuccess(true);
  }

  get alertPopup() {
    // if success
    if (this.openSuccess) return this.onSuccessData;
    // if success partially
    else if (this.isPartial) {
      return {
        onClick: this.onCloseSuccess,
        onDownload: this.onDownloadCsvFailed,
        title: this.$t("customStatus.titleSuccess"),
        message: this.$t("customStatus.partialMessage", {
          status: this.form.status
        }),
        image: "image-modal-warning",
        textCancel: "OK",
        isUsingText: true
      };
    }
    // if input by typing
    else if (this.isErrorTyping) {
      return {
        onClick: this.onResetForm,
        title: `${this.isScan ? "Scan" : "Tambah"} No. STT Gagal`,
        message: this.errorSttNumber,
        image: "image-modal-failed"
      };
    }
    // if input by scan
    else if (this.isErrorValidationScan) {
      return {
        onClick: this.onResetForm,
        title: "Scan No. STT Gagal",
        message: this.errorSttNumber,
        image: "image-modal-failed"
      };
    }
    // failed
    else if (this.isFailed) return this.onFailedData;

    return {
      onClick: undefined,
      onDownload: undefined,
      title: "",
      message: "",
      image: "image-modal-failed"
    };
  }

  get onSuccessData() {
    const data: any = {
      onClick: this.onCloseSuccess,
      title: this.$t("customStatus.titleSuccess", {
        status: this.form.status,
      }),
      message: this.IS_REVERSE_JOURNEY
        ? this.$t("customStatus.successMessageNewStt", {
            listStt: this.listSttNumber.toString(),
            newStt: this.sttReturn.newSttNo
          })
        : this.$t("customStatus.successMessage", {
            status: this.form.status
          }),
      image: "image-modal-success",
      textCancel: "Oke, mengerti",
      isUsingText: false
    };

    if (this.sttReturn.newSttNo?.match(/^66|^77|^78|^89|^94/gi)) {
      data["textCancel"] = "OK";
      data["customStyleButton"] = {
        title: "Print Resi",
        textColor: "white",
        color: "red-lp-100",
        groupOptions: [
          {
            icon: "printer",
            label: "Print Thermal Resi",
            clickFunction: () => this.print("thermal")
          },
          {
            icon: "document-download-outline-grey",
            label: "Print Basic/Save to PDF",
            clickFunction: () => this.print("basic")
          }
        ]
      };
    }
    return data;
  }

  get onFailedData() {
    if (this.IS_REVERSE_JOURNEY) {
      const errorResponse = (this.sttReturn.sttFailed[0] as SttFailedDetail)
        ?.error;
      // error RTS on booking shipment
      if (errorResponse) {
        let ERROR_DETAIL: ErrorSpesificationModal = new ErrorSpesificationModal();

        if (this.IS_REVERSE_JOURNEY) {
          ERROR_DETAIL = ERROR_SPESIFICATION(errorResponse);
        }
        return {
          onClick: () => this.setFailed(false),
          title: ERROR_DETAIL?.headerError,
          message: ERROR_DETAIL?.errorMsg,
          image: "image-modal-warning",
          hideCountError: true
        };
      }
    }
    // default is failed
    return {
      onClick: () => this.setFailed(false),
      onDownload: this.onDownloadCsvFailed,
      title: this.$t("Perubahan Status Gagal !"),
      message: this.$t("customStatus.failedMessage", {
        status: this.form.status
      }),
      image: "image-modal-failed",
      textCancel: "OK",
      isUsingText: true
    };
  }

  // form model
  form = {
    status: "",
    statusName: "",
    sttNumber: "",
    remarks: "" as any,
    reasonCode: "",
    partnerName: "" as any,
    partnerType: "" as any,
    partnerId: "" as any,
    cityCode: "",
    cityName: "",
    originDistrictCode: ""
  };

  get isDisableAddSttNumber() {
    return (
      !this.form.sttNumber ||
      (this.IS_REVERSE_JOURNEY
        ? this.totalStt && this.totalStt < 2
        : this.totalStt >= 250) ||
      (this.IS_MISBOOKING && this.totalStt && this.totalStt < 2) ||
      !this.form.status ||
      this.remarksValidation.disable ||
      (this.isInternal &&
        !(
          this.form.partnerType &&
          this.form.partnerName &&
          this.form.partnerId
        )) ||
      this.validateReasonCode
    );
  }

  get isLoadingShipmentDetail () {
    return ShipmentBookingController.isLoading;
  }

  get isDisabledMisbookingProcess() {
    return (this.IS_MISBOOKING && this.isInternal && this.unableProcessMisbooking);
  }
  get isDisableUpdateProcess() {
    return this.isDisabledMisbookingProcess || !this.form.status || this.remarksValidation.disable;
  }

  // convert value decimal
  isValueDecimal(value: number): number | string {
    return convertDecimal(value);
  }

  // format total kg
  convertTotalKg(value: number) {
    return convertDecimalAfterComma(value, 1);
  }

  // panel pickup
  togglePanel(event: any) {
    if (this.form.status) {
      const refs: any = this.$refs;
      refs.op?.toggle(event);
    }
  }

  // notification handler
  notification = false;
  detailSttNotification: any = null;

  // table
  dataSttToBeUpdate: Array<CustomProcessSttDetail> = [];
  get columns() {
    return [
      {
        name: "No.",
        styleHead: "w-10 text-left whitespace-no-wrap",
        styleCustom: "align-top",
        render: (item: CustomProcessSttDetail, index: number) => {
          return `<div class="text-black-lp-300">${index + 1}</div>`;
        }
      },
      {
        name: `No. STT`,
        styleHead: "w-56 text-left whitespace-no-wrap",
        render: (item: CustomProcessSttDetail) => {
          return `<div class="text-black-lp-300">${item.sttNo}</div>`;
        }
      },
      {
        name: this.$t("No. Referensi"),
        styleHead: "w-56 text-left whitespace-no-wrap",
        render: (item: CustomProcessSttDetail) => {
          return `<div class="text-black-lp-300">${item.sttNoRefExternal}</div>`;
        }
      },
      {
        name: this.$t("Kota Asal"),
        styleHead: "w-44 text-left whitespace-no-wrap",
        render: (item: CustomProcessSttDetail) => {
          return `<div class="text-black-lp-300">${item.sttOriginCityName}</div>`;
        }
      },
      {
        name: this.$t("Kota Tujuan"),
        styleHead: "w-44 text-left whitespace-no-wrap",
        render: (item: CustomProcessSttDetail) => {
          return `<div class="text-black-lp-300">${item.sttDestinationCityName}</div>`;
        }
      },
      {
        name: "Origin",
        styleHead: "w-20 text-left whitespace-no-wrap",
        render: (item: CustomProcessSttDetail) => {
          return `<div class="text-black-lp-300 flex">
                  <div class="rounded px-2 py-0 bg-gray-lp-400">
                      ${item.sttOriginCityId}
                  </div>
                </div>`;
        }
      },
      {
        name: this.$t("Dest."),
        styleHead: "w-20 text-left whitespace-no-wrap",
        render: (item: CustomProcessSttDetail) => {
          return `<div class="text-black-lp-300 flex">
                  <div class="rounded px-2 py-0 bg-gray-lp-400">
                      ${
                        item.isUpdatedByInternal
                          ? item.reverseDestination.returnCityCode
                          : item.sttDestinationCityId
                      }
                  </div>
                </div>`;
        }
      },
      {
        name: this.$t("Status Terakhir"),
        styleHead: "w-44 text-left whitespace-no-wrap",
        render: (item: CustomProcessSttDetail) => {
          return `<div class="text-black-lp-300">${item.sttLastStatusId}</div>`;
        }
      },
      {
        name: this.$t("Berat Kotor"),
        styleHead: "w-56 text-left whitespace-no-wrap",
        render: (item: CustomProcessSttDetail) => {
          return `<div class="text-black-lp-300">${convertDecimalAfterComma(
            Number(item.sttGrossWeight),
            2
          )} Kg</div>`;
        }
      },
      {
        name: this.$t("Berat Dimensi"),
        styleHead: "w-56 text-left whitespace-no-wrap",
        render: (item: CustomProcessSttDetail) => {
          return `<div class="text-black-lp-300">${convertDecimalAfterComma(
            Number(item.sttVolumeWeight),
            2
          )} Kg</div>`;
        }
      },
      {
        name: this.$t("Berat Dikenakan Biaya"),
        styleHead: "w-56 text-left whitespace-no-wrap",
        render: (item: CustomProcessSttDetail) => {
          return `<div class="text-black-lp-300">${convertDecimalAfterComma(
            Number(item.sttChargeableWeight),
            2
          )} Kg</div>`;
        }
      }
    ];
  }

  // role account user
  get roleAccount() {
    if (
      this.dataProfile.account_type ||
      this.dataProfile.account_type_detail.type
    ) {
      if (
        this.dataProfile.isCustomerService ||
        this.dataProfile.isInternalAccount
      ) {
        return "internal";
      } else if (this.dataProfile.account_type.toLowerCase() === "partner") {
        return this.dataProfile.account_type_detail.type;
      }
      return this.dataProfile.account_type;
    }
    return "";
  }

  get listSttNumber() {
    return this.dataSttToBeUpdate.map(e => e.sttNo);
  }

  withPrint = false;
  countSuccess = 0;
  countFailed = 0;
  sttFailedList: Array<any> = [];
  sttReturn: ResponseUpdate = new ResponseUpdate();
  bookedById = 0;

  get IS_RTS(): boolean {
    return !!this.form.status.match(/^RTS$/gi);
  }
  get IS_RTSHQ(): boolean {
    return !!this.form.status.match(/^RTSHQ$/gi);
  }
  get IS_REROUTE(): boolean {
    return !!this.form.status.match(/^REROUTE$/gi);
  }

  get isEditAddress() {
    return CustomProcessController.isEditAddress;
  }

  onCheckRTSConsigneePhone() {
    if (this.isEditAddress && CustomProcessController.consigneePhone) {
      return CustomProcessController.consigneePhone?.value;
    } else {
      return this.formEditAddress.consigneePhone[0]?.value;
    }
  }

  get isUpdatedByInternal() {
    return !!this.dataSttToBeUpdate[0].isUpdatedByInternal
  }
  get isLastStatusMisbooking() {
    return this.dataSttToBeUpdate[0]?.sttLastStatusId === "MISBOOKING"
  }
  get IS_MISBOOKING(): boolean {
    return !!this.form.status.match(/^MISBOOKING$/gi);
  }

  async onSave() {
    this.handleConfirmationSave(false);
    let payload: RequestUpdate = new RequestUpdate();
    const isRerouteMisbooking = this.IS_REROUTE && this.isLastStatusMisbooking
    if (isRerouteMisbooking) {
      this.form.remarks = this.dataSttToBeUpdate[0]?.reverseDestination.remarks
    }

    const consignePhone = Array.isArray(this.formEditAddress.consigneePhone) && this.IS_RTS ? this.onCheckRTSConsigneePhone() : this.formEditAddress.consigneePhone;
    if (this.IS_RTS || this.IS_RTSHQ || this.IS_REROUTE) {
      payload = new RequestUpdate({
        sttDestinationDistrictId: (this.formEditAddress
          .destinationCity as DistrictData).code,
        sttReceiptName: this.formEditAddress.consigneeName,
        sttReceiptAddress: this.formEditAddress.consigneeAddress,
        sttReceiptAddressType: this.formEditAddress.addressType || "home",
        sttReceiptPhone: consignePhone,
        sttPiecePerPack: this.formEditAddress.sttPiecePerPack,
        sttNextCommodity: this.formEditAddress.sttNextCommodity
      });
    }

    if (this.isUpdatedByInternal && ((this.IS_REROUTE || this.IS_MISBOOKING))) {
      payload = new RequestUpdate({
        ...payload,
        isUpdatedByInternal: this.isUpdatedByInternal,
        reverseDestination: this.dataSttToBeUpdate[0].reverseDestination,
        sttDestinationDistrictId: this.dataSttToBeUpdate[0].reverseDestination
          .returnDistrictCode
      });
    }

    if (this.IS_MISBOOKING) {
      payload = new RequestUpdate({
        ...payload,
        reverseDestination: this.reverseDestinationMissBooking
      })
    }

    const res: ResponseUpdate = await CustomProcessController.onUpdate(
      new UpdateStatusApiRequest({
        payload: new RequestUpdate({
          ...payload,
          customProcessStatus: this.form.status,
          sttNo: this.listSttNumber,
          remarks: this.isHalCd ? this.form.reasonCode : this.form.remarks,
          partnerId: this.isInternal ? Number(this.form.partnerId) : 0,
          sttBookedBy: this.IS_REVERSE_JOURNEY
            ? this.partnerDetail.partnerIdSttReturn ||
              (this.form.partnerName as any)?.id
            : 0,
          sttProductTypeCode:
            (this.formEditAddress.product as ProductDeliveryData).name ||
            String(this.formEditAddress.product),
          sttCommodityCode: (this.formEditAddress.commodity as Commodity)
            .commodity_code,
          sttGoodsStatus:
            (this.formEditAddress.goodsStatus as OptionsClass).value ||
            String(this.formEditAddress.goodsStatus),
          sttTaxNumber: this.formEditAddress.taxNumber,
          sttDestinationZipCode: this.formEditAddress.postalCode
        })
      })
    );
    this.onHaveResults(res);
  }

  get unableProcessMisbooking() {
    return CustomProcessController.unableProccessMisbooking;
  }

  onHaveResults(res: ResponseUpdate) {
    if (res?.totalSttFailed || res?.totalSttSuccess) {
      this.sttReturn = res;
      // checking for update data
      if (res.totalSttFailed) this.sttFailedList = res.sttFailed;
      if (res.totalSttSuccess && res.totalSttFailed) {
        this.countFailed = res.totalSttFailed;
        this.countSuccess = res.totalSttSuccess;
        this.isPartial = true;
      } else if (res.totalSttFailed) {
        this.countFailed = res.totalSttFailed;
        this.setFailed(true);
      } else {
        this.setOpenSuccess(true);
      }
    }

    // with print
    if (this.withPrint && res?.totalSttSuccess) {
      this.refs.print.printManifest(res.customProcessId);
    }
  }


  get reverseDestination() {
      return CustomProcessController.reverseDestination;
  }

  get reverseDestinationMissBooking() {
    return {
      remarks: this.reverseDestination.remarks || this.form.remarks || this.form.remarks?.remarks ||  "",
      sttProductType: this.reverseDestination.sttProductType,
      returnCityCode: this.reverseDestination.returnCityCode,
      returnCityName: this.formEditAddress.destinationCity?.cityName || "",
      returnDistrictCode: this.reverseDestination.returnDistrictCode,
      returnDistrictName: this.reverseDestination.returnDistrictName,
      returnReceiptAddress: this.reverseDestination.returnReceiptAddress,
      returnReceiptAddressType: this.reverseDestination.returnReceiptAddressType,
      returnReceiptName: this.reverseDestination.returnReceiptName,
      returnReceiptPhone: this.reverseDestination.returnReceiptPhone,
      sttDestinationZipCode: this.reverseDestination.sttDestinationZipCode,
      sttCommodityCode: this.reverseDestination.sttCommodityCode,
      sttGoodsStatus: this.reverseDestination.sttGoodsStatus,
      sttTaxNumber: this.reverseDestination.sttTaxNumber,
      sttPiecePerPack: this.formEditAddress.sttPiecePerPack,
      sttNextCommodity: this.formEditAddress.sttNextCommodity,
    };
  }

  get shipmentDetail() {
    return CustomProcessController.shipmentDetail;
  }


  createAndPrint(withPrint: boolean) {
    const refs: any = this.$refs;
    refs.op?.hide();
    this.withPrint = withPrint;
    this.handleConfirmationSave(true);
  }

  // handle route leave and modal
  // navigation
  get openSuccess(): boolean {
    return CustomProcessController.isOpenSuccess;
  }
  setOpenSuccess(value: boolean) {
    CustomProcessController.setOpenSuccess(value);
  }

  // failed for create
  get isFailed(): boolean {
    return CustomProcessController.isFailed;
  }
  setFailed(value: boolean) {
    CustomProcessController.setFailed(value);
  }

  openConfirmationLeave = false;
  openConfirmationSave = false;
  answer = false;
  newPath = "";
  handleConfirmationLeave(value: boolean) {
    this.openConfirmationLeave = value;
  }
  handleConfirmationSave(value: boolean) {
    this.openConfirmationSave = value;
  }
  goBack() {
    this.$router.push("/custom-status");
  }
  onOpenClosePage(to: any) {
    this.handleConfirmationLeave(true);
    this.newPath = to.path;
  }
  handlerClose() {
    this.handleConfirmationLeave(false);
    this.answer = true;
    this.$router.push(this.newPath);
  }
  handlerCancelClose() {
    this.answer = false;
    this.handleConfirmationLeave(false);
  }
  onCloseSuccess() {
    this.goBack();
  }

  // check remarks stt
  get remarksValidation() {
    return {
      error: false,
      errorCaption: "",
      disable:
        !this.IS_REVERSE_JOURNEY &&
        !this.form.remarks &&
        !this.isHalCd &&
        !this.isRejected &&
        !this.IS_CNXCD &&
        !this.form.status.includes("OCC"),
      inputChecked: false,
      showMaxLength: true
    };
  }

  // partners area type
  partnerAreaTypeList = [
    {
      name: "Pilih Tipe konsol",
      value: ""
    },
    {
      name: "Consolidator",
      value: "console"
    },
    {
      name: "Sub Consolidator",
      value: "sub-console"
    }
  ];

  isOpenPartnerType = false;
  onOpenSelectPartnerType() {
    this.isOpenPartnerType = true;
  }

  onCloseSelectPartnerType() {
    this.isOpenPartnerType = false;
  }

  onSelectPartnerType(_: string, value: string) {
    this.onCloseSelectPartnerType();
    this.form.partnerType = value;
    this.form.partnerId = "";
    this.form.partnerName = "";
    PartnerController.getConsolidatorsByCity({
      search: "",
      page: 1,
      limit: 10,
      cityCode: "",
      type: this.form.partnerType,
      status: ""
    });
  }

  get remarksMisBookingCust() {
    return this.form.remarks.includes('Customer') && this.IS_MISBOOKING;
  }

  get remarksMisBookingPOS() {
    return this.form.remarks.includes('POS') && this.IS_MISBOOKING;
  }

  get handleConfirmationTitle() {
    let result = { title: "", message: "" }
    switch (this.form.status) {
      case "MISBOOKING":
      case "REROUTE":
      result = {title: 'Konfirmasi update status?', message: 'Pastikan kembali seluruh detail STT sudah benar dan sesuai, karena akan dilakukan pemotongan saldo POS terkait.'}
      break;
      case "REJECTED":
      result = {title: 'Update status STT ke REJECTED?', message: 'Pastikan kembali seluruh detail STT sudah sesuai.'}
      break;
      default:
      result = {title: this.$t('Konfirmasi Perubahan'), message: this.$t('Pastikan kembali, seluruh detail STT sudah benar dan sesuai.')}
    }
    return result;
  }

// partners area type
  remarksMisbooking = [
    {
      name: "Pilih Remarks",
      value: ""
    },
    {
      name: "Permintaan perubahan alamat dari Customer",
      value: "Permintaan perubahan alamat dari Customer"
    },
    {
      name: "Permintaan perubahan alamat dari POS",
      value: "Permintaan perubahan alamat dari POS"
    }
  ];

  isOpenRemarksMisbooking = false;
  onOpenSelectRemarksMisbooking() {
    this.isOpenRemarksMisbooking = true;
  }

  onCloseSelectRemarksMisbooking() {
    this.isOpenRemarksMisbooking = false;
  }

  onSelectRemarksMisbooking(_: string, value: string) {
    this.onCloseSelectRemarksMisbooking();
    this.form.remarks = value;
    CustomProcessController.setRemarksMisbooking(value);
  }


  isLoadingPartners = true;
  partnersData: any[] = [];
  filterPartners = debounce(async (search: string, isSearchBy3LC = false) => {
    this.isLoadingPartners = true;
    const resp : any = await PartnerController.fetchPartnerApi({
      search: search,
      page: 1,
      limit: 10,
      type: this.IS_REVERSE_JOURNEY ? "pos" : this.form.partnerType,
      parentId: this.IS_REVERSE_JOURNEY ? this.parentId : "",
      status: "not-banned",
      "search_by_3lc" : isSearchBy3LC,
      "use_location": true
    });
    this.partnersData = resp.filter((key: ClientPartnerOptions) =>
      key.contractStatus.match(/^active|^nearly-expired/gi)
    );
    if (isSearchBy3LC) {
      this.partnersData = resp.map((key: any) => ({name: key.cityCode, id: key.id, key}))
    }
    this.isLoadingPartners = false;
  }, 250);

  onChangePartner(value: any) {
    this.form.partnerName = value;
    this.form.partnerId = value.id;
    this.form.cityName = value.key?.cityName;
    this.form.originDistrictCode = value?.key.districtCode || "";
    if (this.IS_MISBOOKING) {
      this.form.cityCode = value?.key.cityCode || "";
    }
  }

  get showFilterPOS() {
    return (this.IS_RTS || this.IS_RTSHQ || this.IS_REROUTE || this.IS_MISBOOKING) && this.totalStt;
  }


  get IS_REVERSE_JOURNEY() {
    return !!this.form.status.match(/RTS/gi) || this.IS_REROUTE;
  }

  get IS_CNXCD() {
    return !!this.form.status.match(/^CNXCD$/gi) || this.IS_REROUTE;
  }

  // get account type
  get isInternal() {
    return (
      CustomProcessController.isInternal || this.dataProfile.isCustomerService
    );
  }

  get isConsole() {
    return CustomProcessController.isConsole
  }

  get sttId() {
    return this.dataSttToBeUpdate[0]?.sttId || "";
  }

  sttBookedById = 0
  async getDetailStt(sttFromCustomProcess: CustomProcessSttDetail) {
    const response: ShipmentBookingDetail = await ShipmentBookingController.getBookingDetail(
      {
        id: Number(this.sttId),
        isAuth: false
      }
    );
    const districtData: DistrictData[] = await LocationController.getDistrictList(
      {
        search: response.sttDestinationDistrictName,
        status: "active",
        page: 1,
        limit: 10,
        cache: false
      }
    );

    this.sttBookedById = response.sttBookedById

    const isFtzLocation = !!AccountController.accountData.account_location.city_free_trade_zone.match(
      /yes/gi
    );

    const isFtzDestinationByInternal = this.isInternal && this.IS_MISBOOKING && districtData[0].isFtz === "yes";
    const isFtz =
      isFtzLocation ||
      !!response.sttPiecePerPack ||
      !!response.sttNextCommodity ||
      isFtzDestinationByInternal
    const sttIsInterpack = !!response.sttProductTypeName.match(/^INTERPACK$/);

    let tempData: CustomProcessEditAddressForm = new CustomProcessEditAddressForm(
      {
        isFtz,
        isFtzEditable:
          isFtzLocation || !!response.sttOriginCityId.match(/^BTH$/gi) || isFtzDestinationByInternal,
        sttPiecePerPack: response.sttPiecePerPack,
        sttNextCommodity: response.sttNextCommodity || (isFtz ? "0" : ""),
        sttIsInterpack: !this.IS_RTS && sttIsInterpack,
        postalCode:
          !this.IS_RTS && sttIsInterpack ? response.postalCodeDestination : "",
        addressType: response.sttRecipientAddressType,
        destinationCity: new DistrictData({
          isCod: districtData[0]?.isCod.toLowerCase()
        }),
        product:
          (this.IS_RTS || this.IS_REROUTE || this.IS_RTSHQ) &&
          !!response.sttProductTypeName.match(
            /^ONEPACK$|^BOSSPACK$|^REGPACK$/gi
          )
            ? "REGPACK"
            : response.sttProductTypeName,
        commodity: new Commodity({
          commodity_id: response.sttCommodityId,
          commodity_name: response.sttCommodityName,
          commodity_code: response.sttCommodityCode
        }),
        goodsStatus: response.sttSenderStatus,
        taxNumber: response.sttTaxNumber,
        sttNumber: response.sttNo,
        remarks: this.IS_MISBOOKING ? this.form.remarks : ""
      }
    );

    if (this.IS_REROUTE || this.IS_MISBOOKING) {
      tempData = new CustomProcessEditAddressForm({
        ...tempData,
        sttIsCod: response.sttCod.toLowerCase(),
        destinationCity: new DistrictData({
          isCod: districtData[0].isCod.toLowerCase(),
          destinationZipCode: districtData[0].originZipCode,
          cityCode: response.sttDestinationCityId,

        }),
        sttIsDo: response.sttClient.clientIsDo,
        statusStt: this.IS_MISBOOKING ? "MISBOOKING" : "REROUTE"
      });
    }
    if (this.IS_RTS) {
      const isReferToOrigin = sttFromCustomProcess.isReturnToOriginAddress;
      this.formEditAddress = new CustomProcessEditAddressForm({
        ...tempData,
        senderName: response.sttRecipientName,
        senderAddress: response.sttRecipientAddress,
        senderPhone: convertPhoneNumberV2(response.sttRecipientPhone),
        consigneePhone: this.onHandleConsigneePhoneRTS(response, sttFromCustomProcess),
        consigneeName: isReferToOrigin
          ? sttFromCustomProcess.sttReturnReceiptName
          : response.sttSenderName,
        consigneeAddress: isReferToOrigin
          ? sttFromCustomProcess.sttReturnReceiptAddress
          : response.sttSenderAddress,
        originCity: new DistrictData({
          name: response.sttDestinationDistrictName,
          cityName: response.sttDestinationCityName,
          code: response.sttDestinationDistrictId
        }),
        destinationCity: new DistrictData({
          ...(tempData.destinationCity as DistrictData),
          name: isReferToOrigin
            ? sttFromCustomProcess.sttReturnDistrictName
            : response.sttOriginAddress,
          cityName: isReferToOrigin
            ? sttFromCustomProcess.sttReturnCityName
            : response.sttOriginCityName,
          code: isReferToOrigin
            ? sttFromCustomProcess.sttReturnDistrictCode
            : response.sttOriginDistrictId
        }),
        sttIsDo: response.sttClient.clientIsDo,
        sttIsCod: response.sttCod.toLowerCase(),
        sttIsDfod: response.sttIsDfod,
        statusStt: "RTS",
        shipmentId: response.sttReverseJourneyShipmentId
          ? response.sttReverseJourneyShipmentId
          : response.sttShipmentId,
        codHandling: response.codHandling
      });
    } else {
      this.formEditAddress = new CustomProcessEditAddressForm({
        ...tempData,
        senderName: response.sttSenderName,
        senderAddress: response.sttSenderAddress,
        senderPhone: convertPhoneNumberV2(response.sttSenderPhone),
        consigneePhone: convertPhoneNumberV2(response.sttRecipientPhone),
        consigneeName: response.sttRecipientName,
        consigneeAddress: response.sttRecipientAddress,
        originCity: new DistrictData({
          name: response.sttOriginAddress,
          cityName: response.sttOriginCityName,
          code: response.sttOriginDistrictId
        }),
        destinationCity: new DistrictData({
          ...(tempData.destinationCity as DistrictData),
          name: response.sttDestinationDistrictName,
          cityName: response.sttDestinationCityName,
          code: response.sttDestinationDistrictId
        })
      });
    }
  }

  onHandleConsigneePhoneRTS(
    response: ShipmentBookingDetail,
    sttFromCustomProcess: CustomProcessSttDetail
  ) {
    // isReturnToOriginAdress = true, ketika stt last statusnya STI-DEST
    if (!sttFromCustomProcess.isReturnToOriginAddress) return convertPhoneNumberV2(response.sttSenderPhone);
    const result: any = sttFromCustomProcess.sttReturnReceiptPhone.split(",");
    return result.length > 1
      ? result.map((name: any) => {
          return { name, value: convertPhoneNumberV2(name) };
        })
      : convertPhoneNumberV2(result?.toString());
  }

  formEditAddress: CustomProcessEditAddressForm = new CustomProcessEditAddressForm();
  async assignDataEditAddress(newData: CustomProcessEditAddressForm) {
    this.formEditAddress = newData;
  }

  editAddress = false;
  showEditAddress() {
    this.editAddress = true;
  }

  closeEditAddress(status: boolean) {
    this.editAddress = status;
  }

  get isAbleToEditAddressReceiver() {
    return this.IS_RTS ||
      (this.IS_REROUTE && this.isConsole) ||
      (this.IS_MISBOOKING && this.isInternal);
  }

  partnerDetail: PartnerDetail = new PartnerDetail();
  get partnerSttReturn() {
    return {
      id: this.partnerDetail.partnerIdSttReturn,
      name: this.partnerDetail.partnerNameSttReturn
    };
  }
  async fetchDetailPartner() {
    if (this.dataProfile.account_type_detail.partnerIdSttReturn) {
      try {
        this.partnerDetail = await PartnerController.fetchPartnerDetail({
          id: this.parentId,
          tieringLevel: false
        });
      } catch (err) {
        this.partnerDetail = new PartnerDetail();
      }
    }
  }

  get isHalCd() {
    return this.form.status === "HALCD";
  }

  get isRejected(): boolean {
    return !!this.form.status.match(/^REJECTED$/gi);
  }

  getReasonList() {
    PodDexController.getReasonList(
      new ReasonListDex({
        statusCode: this.form.status,
        reasonStatus: "active"
      })
    );
  }

  get reasonList() {
    const res = [{ name: "Pilih alasan", value: "" }];
    PodDexController.reasonList.forEach((key: ReasonData) => {
      res.push({
        name: key.reasonDescription,
        value: key.reasonCode
      });
    });
    return res;
  }

  reasonSelect = false;
  onOpenReasonSelect(value: boolean) {
    this.reasonSelect = value;
  }

  onSelectReasonType(name: string, value: string) {
    this.form.reasonCode = value;
    this.form.remarks = name;
    this.reasonSelect = false;
  }

  get validateReasonCode() {
    return (this.isHalCd && !this.form.reasonCode) || (this.isRejected && !this.form.remarks);
  }
}
